/* istanbul ignore next */
export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => (next) => async (action) => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }

    const { promise, types, ...rest } = action;

    if (!promise) {
      return next(action);
    }

    const [REQUEST, SUCCESS, FAILURE] = types;

    next({ ...rest, type: REQUEST });

    let result = null;

    try {
      result = await promise(client);
      next({ ...rest, result, type: SUCCESS });
    } catch (error) {
      result = error;
      next({ ...rest, error, type: FAILURE });
    }

    return result;
  };
}
