import { useEffect } from 'react';

const DEFAULT_OPTIONS = {
  subtree: true,
  childList: true,
  attributes: true,
  attributeFilter: ['placeholder'],
  characterData: true,
};

export const useMutationObserver = (rootEl, callback, ignoreTranslation) => {
  useEffect(() => {
    if (!ignoreTranslation) {
      const obs = new MutationObserver(callback);
      obs.observe(rootEl, DEFAULT_OPTIONS);
      return function cleanup() {
        /* istanbul ignore else */
        if (obs) {
          obs.takeRecords();
          obs.disconnect();
        }
      };
    }
  }, [rootEl, callback, ignoreTranslation]);

  return null;
};
