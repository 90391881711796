// @flow
import type { Moment } from 'moment/moment';
import api from 'config/api';
import * as config from 'config/rewritable-config';
import { fetchPermissions } from 'modules/permissions';
import constantsGenerator from 'utils/constantsGenerator';
import { formatMDYDate } from 'utils/date';
import { FeatureFlag } from 'constants/featureFlags';
import { requestType } from 'constants/ferpaSign';
import { notify, notifyError } from '../notification';
import { isFeatureFlagAllowed } from '../../utils/permissions';
import { FerpaState } from '../../constants/ferpaSign';

const safariBrowserRegex = /^((?!chrome|android).)*safari/i;
export const COMMON_APP = { PERMISSION_GROUP: 'common-app', MATCHED_PERMISSION: 'account_matched' };

const generateConstants = constantsGenerator('fc/commonApp');

const [
  COMMON_APP_MATCH,
  COMMON_APP_MATCH_SUCCESS,
  COMMON_APP_MATCH_FAIL,
]: string[] = generateConstants('COMMON_APP_MATCH');

const [
  COMMON_APP_APPLICANT,
  COMMON_APP_APPLICANT_SUCCESS,
  COMMON_APP_APPLICANT_FAIL,
]: string[] = generateConstants('COMMON_APP_APPLICANT_DETAILS');

const [
  COMMON_APP_MATCH_NOT_NEEDED,
  COMMON_APP_MATCH_NOT_NEEDED_SUCCESS,
  COMMON_APP_MATCH_NOT_NEEDED_FAIL,
]: string[] = generateConstants('COMMON_APP_MATCH_NOT_NEEDED');

export const [
  COMMON_APP_CONNECT,
  COMMON_APP_CONNECT_SUCCESS,
  COMMON_APP_CONNECT_FAIL,
]: string[] = generateConstants('COMMON_APP_CONNECT');

export const [
  COMMON_APP_FERPA,
  COMMON_APP_FERPA_SUCCESS,
  COMMON_APP_FERPA_FAIL,
]: string[] = generateConstants('COMMON_APP_FERPA');

export const [
  CA_MATCH_AFTER_CONNECT,
  CA_MATCH_AFTER_CONNECT_SUCCESS,
  CA_MATCH_AFTER_CONNECT_FAIL,
]: string[] = generateConstants('COMMON_APP_MATCH_AFTER_CONNECT');

export const [
  COMMON_APP_FERPA_SIGN,
  COMMON_APP_FERPA_SIGN_SUCCESS,
  COMMON_APP_FERPA_SIGN_FAIL,
]: string[] = generateConstants('COMMON_APP_FERPA_SIGNED');

type State = {
  match: {
    error: boolean,
  },
  ferpaWaived: boolean,
  caApplicantId: number,
  caEmail: String,
  caApplicantName: String,
};

const initialState: State = {
  submitting: false,
  ferpaloading: false,
  match: {
    error: false,
  },
  ferpaWaived: false,
  caApplicantId: 0,
  caEmail: '',
  caApplicantName: '',
};

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case COMMON_APP_MATCH:
    case COMMON_APP_CONNECT:
    case CA_MATCH_AFTER_CONNECT:
      return {
        ...state,
        submitting: true,
      };
    case COMMON_APP_MATCH_SUCCESS:
      return {
        ...state,
        submitting: false,
        match: {
          ...state.match,
          ...action.result,
        },
      };
    case COMMON_APP_MATCH_NOT_NEEDED_SUCCESS:
      return {
        ...state,
        submitting: false,
        match: {
          ...state.match,
          ...action.result,
        },
      };
    case COMMON_APP_MATCH_FAIL:
      return {
        ...state,
        submitting: false,
        match: {
          error: true,
        },
      };
    case COMMON_APP_CONNECT_SUCCESS:
    case CA_MATCH_AFTER_CONNECT_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case COMMON_APP_CONNECT_FAIL:
    case CA_MATCH_AFTER_CONNECT_FAIL:
      return {
        ...state,
        submitting: false,
        match: {
          error: true,
        },
      };
    case COMMON_APP_APPLICANT:
    case COMMON_APP_APPLICANT_SUCCESS:
      return {
        ...state,
        caApplicantId: action?.result?.applicantId || 0,
        caEmail: action?.result?.username || '',
        caApplicantName: `${action?.result?.caFirstName || ''} ${
          action?.result?.caLastName || ''
        }`.trim(),
      };
    case COMMON_APP_APPLICANT_FAIL:
      return {
        ...state,
      };
    case COMMON_APP_FERPA:
      return {
        ...state,
        ferpaloading: true,
      };
    case COMMON_APP_FERPA_FAIL:
    case COMMON_APP_FERPA_SUCCESS:
      return {
        ...state,
        ferpaloading: false,
      };
    case COMMON_APP_FERPA_SIGN:
    case COMMON_APP_FERPA_SIGN_SUCCESS:
      return {
        ...state,
        ferpaWaived: action?.result?.ferpaStatus === FerpaState.WAIVED,
      };
    case COMMON_APP_FERPA_SIGN_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}

// eslint-disable-next-line no-unused-vars
export function commonAppMatch(email: string, dateOfBirth: Moment) {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [COMMON_APP_MATCH, COMMON_APP_MATCH_SUCCESS, COMMON_APP_MATCH_FAIL],
      promise: (client: Object) =>
        client.post(`${api.commonApp}/match`, {
          data: {
            email,
            dateOfBirth: formatMDYDate(dateOfBirth),
          },
        }),
    }).then((response: Object) => {
      if (response.message) {
        const { matched, message, fixes } = response;
        dispatch(
          notify({
            type: matched ? 'success' : 'danger',
            title: matched ? 'Confirmation' : '',
            content: message,
            fixes,
          })
        );
      }
    });
}

export function commonAppMatchNotNeeded() {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [
        COMMON_APP_MATCH_NOT_NEEDED,
        COMMON_APP_MATCH_NOT_NEEDED_SUCCESS,
        COMMON_APP_MATCH_NOT_NEEDED_FAIL,
      ],
      promise: (client: Object) => client.post(`${api.commonApp}/not-needed`),
    });
}

export function commonAppConnect() {
  return (dispatch: Function, getState: Function): Promise<*> =>
    dispatch({
      types: [COMMON_APP_CONNECT, COMMON_APP_CONNECT_SUCCESS, COMMON_APP_CONNECT_FAIL],
      promise: (client: Object) => client.post(`${api.commonAppNext}/request-id`),
    }).then((response: Object) => {
      if (response.success && response.data) {
        const { featureFlags } = getState();
        const isCommonAppNextConnectSameTabEnabled =
          featureFlags.loaded &&
          isFeatureFlagAllowed(FeatureFlag.PostEdocsNextConnectSameTab, featureFlags.flags);

        const { partnerId, requestId, ceebCode } = response.data;
        const commonAppConnectUrl = `${config.COMMON_APP_BASE_URL}/login?ClientPartnerId=${partnerId}&RequestId=${requestId}&CeebCode=${ceebCode}&RedirectURL=colleges/applying-to`;

        // open in the same tab if safari browser
        if (
          isCommonAppNextConnectSameTabEnabled &&
          navigator.userAgent &&
          safariBrowserRegex.test(navigator.userAgent)
        ) {
          window.location.assign(commonAppConnectUrl);
        } else {
          window.open(commonAppConnectUrl, '_blank');
        }
      } else {
        const { message } = response;
        dispatch(
          notify({
            title: '',
            type: 'danger',
            content: message,
          })
        );
      }
    });
}

export function commonAppFerpaWaive(applicantId) {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [COMMON_APP_FERPA, COMMON_APP_FERPA_SUCCESS, COMMON_APP_FERPA_FAIL],
      promise: (client: Object) =>
        client.post(`${api.commonAppNext}/ferpa-request-id`, {
          data: {
            applicantId: applicantId,
            redirectURL: `colleges/applying-to?applicantId=${applicantId}&requestType=${requestType}`,
            requestType: requestType,
          },
        }),
    }).then((response: Object) => {
      if (response.success && response.data) {
        const { requestId } = response.data;
        const commonAppConnectUrl = `${config.COMMON_APP_BASE_URL}/login?requestId=${requestId}&requestType=${requestType}`;
        // open in the same tab if safari browser
        if (navigator.userAgent && safariBrowserRegex.test(navigator.userAgent)) {
          window.location.assign(commonAppConnectUrl);
        } else {
          window.open(commonAppConnectUrl, '_blank');
        }
      } else {
        const { ferpaMessage } = response;
        dispatch(
          notify({
            title: '',
            type: 'danger',
            content: ferpaMessage,
          })
        );
      }
    });
}

export function matchAccountAfterConnect({ requestId, applicantId }) {
  return (dispatch: Function, getState: Function): Promise<*> =>
    dispatch({
      types: [CA_MATCH_AFTER_CONNECT, CA_MATCH_AFTER_CONNECT_SUCCESS, CA_MATCH_AFTER_CONNECT_FAIL],
      promise: (client: Object) =>
        client.post(`${api.commonAppNext}/match-after-connect`, {
          data: { requestId, applicantId },
        }),
    })
      .then((response: Object) => {
        // fetch permissions to update match banner
        if (response.matched) {
          dispatch(fetchPermissions());
        }

        // show notification
        if (response.message) {
          const { fixes, matched, message } = response;
          const { featureFlags } = getState();
          const isNewMatchFlagEnabled =
            featureFlags.loaded &&
            isFeatureFlagAllowed(FeatureFlag.MatchAndFerpaAlert, featureFlags.flags);
          if (!isNewMatchFlagEnabled) {
            dispatch(
              notify({
                type: matched ? 'success' : 'danger',
                title: matched ? 'Confirmation' : '',
                content: message,
                fixes,
              })
            );
          } else {
            dispatch(
              notify({
                type: matched ? 'successLight' : 'danger',
                content: message,
                fixes,
                icon: matched ? 'check-circle' : '',
                closeButtonIcon: 'close',
                showCloseButtonText: false,
              })
            );
          }
        }
      })
      .catch((e) => {
        dispatch(notifyError(e));
      });
}

export function getMatchedStudentDetails(studentId) {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [COMMON_APP_APPLICANT, COMMON_APP_APPLICANT_SUCCESS, COMMON_APP_APPLICANT_FAIL],
      promise: (client: Object) => client.get(`${api.commonAppNext}/students/${studentId}`),
    });
}

export function getCurrentUserFerpaSignDetail(studentId) {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [COMMON_APP_FERPA_SIGN, COMMON_APP_FERPA_SIGN_SUCCESS, COMMON_APP_FERPA_SIGN_FAIL],
      promise: (client: Object) => client.get(`${api.commonAppNext}/students/${studentId}`),
    });
}
