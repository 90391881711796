// @flow
import get from 'lodash/get';
import constantsGenerator from 'utils/constantsGenerator';
import api, { host } from 'config/api';
import type { College } from 'types/colleges';
import { normalizeUrl } from 'utils/link';
import { paginatedSearch } from 'utils/pagination';
import getCollegesProfileGQLQuery from 'containers/Colleges/Profiles/query';

import { notify } from '../notification';

type CollegeID = $PropertyType<College, 'id'>;

const generateConstants = constantsGenerator('fc/applying');
const EDIT_APPLICATION = 'fc/applying/EDIT_APPLICATION';

const RESET_EDITS = 'fc/applying/RESET_EDITS';

// remember last page we loaded. Set it to -1 so it's immediately invalidated
let lastPage: number = -1;

const [
  GET_COLLEGES_IAM_APPLYING,
  GET_COLLEGES_IAM_APPLYING_SUCCESS,
  GET_COLLEGES_IAM_APPLYING_FAIL,
]: string[] = generateConstants('GET_COLLEGES_IAM_APPLYING');

const [
  GET_COLLEGES_IAM_APPLYING_IN_ISOLATION,
  GET_COLLEGES_IAM_APPLYING_IN_ISOLATION_SUCCESS,
  GET_COLLEGES_IAM_APPLYING_IN_ISOLATION_FAIL,
]: string[] = generateConstants('GET_COLLEGES_IAM_APPLYING_IN_ISOLATION');

const [
  SAVE_COLLEGES_IAM_APPLYING,
  SAVE_COLLEGES_IAM_APPLYING_SUCCESS,
  SAVE_COLLEGES_IAM_APPLYING_FAIL,
]: string[] = generateConstants('SAVE_COLLEGES_IAM_APPLYING');

const [
  SAVE_COLLEGES_IAM_ATTENDING,
  SAVE_COLLEGES_IAM_ATTENDING_SUCCESS,
  SAVE_COLLEGES_IAM_ATTENDING_FAIL,
]: string[] = generateConstants('SAVE_COLLEGES_IAM_ATTENDING');

const [
  UPDATE_COLLEGES_IAM_APPLYING,
  UPDATE_COLLEGES_IAM_APPLYING_SUCCESS,
  UPDATE_COLLEGES_IAM_APPLYING_FAIL,
]: string[] = generateConstants('UPDATE_COLLEGES_IAM_APPLYING');

const [
  UPDATE_COLLEGES_IAM_APPLYING_TO_DEADLINE,
  UPDATE_COLLEGES_IAM_APPLYING_TO_DEADLINE_SUCCESS,
  UPDATE_COLLEGES_IAM_APPLYING_TO_DEADLINE_FAIL,
]: string[] = generateConstants('UPDATE_IAM_APPLYING_COLLEGES_DEADLINE');

const [
  DELETE_COLLEGES_IAM_APPLYING,
  DELETE_COLLEGES_IAM_APPLYING_SUCCESS,
  DELETE_COLLEGES_IAM_APPLYING_FAIL,
]: string[] = generateConstants('DELETE_COLLEGES_IAM_APPLYING');

const [NEXT_PAGE, NEXT_PAGE_SUCCESS, NEXT_PAGE_FAILURE]: string[] = generateConstants(
  'NEXT_PAGE_APPLYING'
);

export const [
  GET_COLLEGE_PRODUCTS,
  GET_COLLEGE_PRODUCTS_SUCCESS,
  GET_COLLEGE_PRODUCTS_FAIL,
]: string[] = generateConstants('GET_COLLEGES_PRODUCTS');

export const [
  GET_COLLEGES_PROFILES_NEXT,
  GET_COLLEGES_PROFILES_NEXT_SUCCESS,
  GET_COLLEGES_PROFILES_NEXT_FAIL,
]: string[] = generateConstants('GET_COLLEGES_PROFILES_NEXT');

const CHANGE_COLLEGE_APP_VALUE = 'fc/applying/CHANGE_COLLEGE_APP_VALUE';

const CHANGE_IS_LOADING_LEGACY_MATCHES = 'fc/applying/CHANGE_IS_LOADING_LEGACY_MATCHES';

export const applicationTypes = {
  RD: 'Regular Decision',
  ED: 'Early Decision',
  EA: 'Early Action',
  EDII: 'Early Decision II',
  ROLL: 'Rolling',
  REA: 'Restrictive Early Action',
  PRI: 'Priority',
  OTHR: 'Other',
  EAII: 'Early Action II',
};

type State = {
  results: Array<Object>,
  entities: Object,
  resultsWithDeleted: Array<Object>,
  entitiesWithDeleted: Object,
  loading: boolean,
  loaded: boolean,
  edits: Object,
  totalItems: number,
  totalPages: number,
  allResults: Array<Object>,
  allEntities: Object,
  loadingAll: boolean,
  loadedAll: boolean,
  wereLegacyMatchesLoaded: boolean,
};

const initialState: State = {
  results: [],
  entities: {},
  resultsWithDeleted: [],
  entitiesWithDeleted: {},
  loading: false,
  loaded: false,
  edits: {
    entities: {},
  },
  totalItems: 0,
  totalPages: 0,
  allResults: [],
  allEntities: {},
  loadingAll: false,
  loadedAll: false,
  wereLegacyMatchesLoaded: false,
};

const filterItems = (item) => !item.isDeleted;
const filterItemsById = (item) => !!item.id;

const normalizeItems = (res, removeDeleted = true) =>
  res.data.filter(removeDeleted ? filterItems : filterItemsById).reduce((items, item) => {
    if (item.id) {
      items[item.id] = {
        ...item.college,
        ...item,
        attending: item.attending === 1,
        url: normalizeUrl(item.college.url),
        applicationId: item.id,
        college: undefined,
        collegeId: item.college && item.college.id,
      };
    }
    return items;
  }, {});

const collectIds = (res, removeDeleted = true) =>
  res.data.filter(removeDeleted ? filterItems : filterItemsById).map((item) => item.id);

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case NEXT_PAGE:
    case GET_COLLEGES_IAM_APPLYING:
      return {
        ...state,
        loading: true,
      };
    case GET_COLLEGES_IAM_APPLYING_IN_ISOLATION:
      return {
        ...state,
        loadingAll: true,
      };
    case SAVE_COLLEGES_IAM_ATTENDING_SUCCESS:
      return {
        ...state,
        results: collectIds({ data: action.result.data }),
        entities: normalizeItems({ data: action.result.data }),
        loading: false,
        loaded: true,
      };
    case GET_COLLEGES_IAM_APPLYING_SUCCESS: {
      const collegesImApplying = normalizeItems(action.result);
      Object.keys(collegesImApplying).forEach((key) => {
        collegesImApplying[key].requestInformationUrl = '';
      });
      return {
        ...state,
        results: collectIds(action.result),
        entities: collegesImApplying,
        resultsWithDeleted: collectIds(action.result, false),
        entitiesWithDeleted: normalizeItems(action.result, false),
        totalPages: action.result.totalPages,
        loading: false,
        loaded: true,
        totalItems: action.result.totalItems,
      };
    }
    case NEXT_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        totalPages: action.result.totalPages,
        results: [...(state.results.concat(collectIds(action.result)) || [])],
        totalItems: action.result.totalItems,
        entities: { ...state.entities, ...normalizeItems(action.result) },
      };
    case NEXT_PAGE_FAILURE:
    case GET_COLLEGES_IAM_APPLYING_IN_ISOLATION_SUCCESS: {
      const collegesImApplying = normalizeItems(action.result);
      Object.keys(collegesImApplying).forEach((key) => {
        collegesImApplying[key].requestInformationUrl = '';
      });
      return {
        ...state,
        allResults: collectIds(action.result),
        allEntities: collegesImApplying,
        loadingAll: false,
        loadedAll: true,
      };
    }
    case GET_COLLEGES_IAM_APPLYING_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    case GET_COLLEGES_IAM_APPLYING_IN_ISOLATION_FAIL:
      return {
        ...state,
        loadingAll: false,
        loadedAll: false,
      };
    case SAVE_COLLEGES_IAM_APPLYING:
      return {
        ...state,
        loading: true,
      };
    case SAVE_COLLEGES_IAM_APPLYING_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case SAVE_COLLEGES_IAM_APPLYING_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    case EDIT_APPLICATION: {
      const { id, field, value } = action;

      return {
        ...state,
        edits: {
          ...state.edits,
          entities: {
            ...state.edits.entities,
            [id]: {
              ...state.edits.entities[id],
              [field]: value,
            },
          },
        },
      };
    }
    case DELETE_COLLEGES_IAM_APPLYING:
      return {
        ...state,
        loading: true,
      };
    case DELETE_COLLEGES_IAM_APPLYING_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case DELETE_COLLEGES_IAM_APPLYING_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
      };

    case CHANGE_COLLEGE_APP_VALUE: {
      const { id, name, value } = action;

      return {
        ...state,
        entities: {
          ...state.entities,
          [id]: {
            ...state.entities[id],
            [name]: value,
          },
        },
      };
    }
    case RESET_EDITS: {
      return {
        ...state,
        edits: {
          entities: {},
        },
      };
    }
    case GET_COLLEGES_PROFILES_NEXT:
    case GET_COLLEGE_PRODUCTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_COLLEGE_PRODUCTS_SUCCESS: {
      let products = action.result;
      // map legacy products to NextGen (required fields only)
      products = products.map((product) => {
        if (product.category === 'NORMAL INFOZAP') {
          return {
            ...product,
            category: 'COMMUNICATE',
            product_id: product.productPlacement, // eslint-disable-line camelcase
            url: product.prettyUrl,
          };
        }
        return product;
      });
      return {
        ...state,
        products,
        loading: false,
        loaded: true,
      };
    }
    case GET_COLLEGES_PROFILES_NEXT_SUCCESS: {
      const products = get(action.result, 'data.findCollegeDetails.hubsWebLinks.premium_links', []);
      return {
        ...state,
        products,
        loading: false,
        loaded: true,
      };
    }
    case GET_COLLEGES_PROFILES_NEXT_FAIL:
    case GET_COLLEGE_PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    case UPDATE_COLLEGES_IAM_APPLYING:
      return {
        ...state,
        submitting: true,
      };
    case UPDATE_COLLEGES_IAM_APPLYING_SUCCESS:
    case UPDATE_COLLEGES_IAM_APPLYING_FAIL:
      return {
        ...state,
        submitting: false,
      };
    case CHANGE_IS_LOADING_LEGACY_MATCHES:
      return {
        ...state,
        wereLegacyMatchesLoaded: action.wereLegacyMatchesLoaded,
      };
    default:
      return state;
  }
}

export function fetchAllApplyingTo() {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [
        GET_COLLEGES_IAM_APPLYING,
        GET_COLLEGES_IAM_APPLYING_SUCCESS,
        GET_COLLEGES_IAM_APPLYING_FAIL,
      ],
      promise: (client: Object) =>
        client.get(`${api.colleges}/colleges-im-applying-to?limit=${FETCH_MAX_RESULTS_LARGE}`),
    });
}

export function fetchAllApplyingToInIsolation() {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [
        GET_COLLEGES_IAM_APPLYING_IN_ISOLATION,
        GET_COLLEGES_IAM_APPLYING_IN_ISOLATION_SUCCESS,
        GET_COLLEGES_IAM_APPLYING_IN_ISOLATION_FAIL,
      ],
      promise: (client: Object) =>
        client.get(`${api.colleges}/colleges-im-applying-to?limit=${FETCH_MAX_RESULTS_LARGE}`),
    });
}

export function searchApplyingToByApplicationId(applicationId: number) {
  const queryString = `filter=${encodeURIComponent(
    JSON.stringify({ 'application.id': `${applicationId}` })
  )}`;

  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [
        GET_COLLEGES_IAM_APPLYING,
        GET_COLLEGES_IAM_APPLYING_SUCCESS,
        GET_COLLEGES_IAM_APPLYING_FAIL,
      ],
      promise: (client: Object) =>
        client.get(`${api.colleges}/colleges-im-applying-to?${queryString}`),
    });
}

export function updateCollegeIamApplyingToDeadline() {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [
        UPDATE_COLLEGES_IAM_APPLYING_TO_DEADLINE,
        UPDATE_COLLEGES_IAM_APPLYING_TO_DEADLINE_SUCCESS,
        UPDATE_COLLEGES_IAM_APPLYING_TO_DEADLINE_FAIL,
      ],
      promise: (client: Object) => client.put(`${api.colleges}/colleges-im-applying-to-deadline`),
    });
}

export function getCollegeProducts(hobsonsId: number) {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [GET_COLLEGE_PRODUCTS, GET_COLLEGE_PRODUCTS_SUCCESS, GET_COLLEGE_PRODUCTS_FAIL],
      promise: (client: Object) => client.get(`${host}/college-products/hid/${hobsonsId}`),
    });
}

export function getCollegeProfilesNext(hobsonsId: number) {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [
        GET_COLLEGES_PROFILES_NEXT,
        GET_COLLEGES_PROFILES_NEXT_SUCCESS,
        GET_COLLEGES_PROFILES_NEXT_FAIL,
      ],
      promise: (client: Object) =>
        client.post(`${api.collegesProfilesNext}`, {
          data: getCollegesProfileGQLQuery(hobsonsId),
        }),
    });
}

// how many pages per request?
const limit = 10;
export const searchApplyingTo = (lastIndex: number) => (
  dispatch: Function,
  getState: Function
): Promise<*> => {
  const totalResults = getState().colleges.applying.results.length;
  const { totalItems } = getState().colleges.applying;

  if (!lastIndex && lastIndex !== 0) {
    lastIndex = 0;
    // if nothing is sent, last page is reset to -1
    lastPage = -1;
  }
  const { requestUrl, types, nextPage, proceed } = paginatedSearch({
    baseUrl: `${api.colleges}/colleges-im-applying-to`,
    lastIndex,
    lastPage,
    limit,
    totalResults,
    totalItems,
    firstPageTypes: [
      GET_COLLEGES_IAM_APPLYING,
      GET_COLLEGES_IAM_APPLYING_SUCCESS,
      GET_COLLEGES_IAM_APPLYING_FAIL,
    ],
    nextPageTypes: [NEXT_PAGE, NEXT_PAGE_SUCCESS, NEXT_PAGE_FAILURE],
  });

  if (!proceed) {
    // $FlowFixMe
    return Promise.resolve(true);
  }

  lastPage = nextPage || -1;
  return dispatch({
    types,
    promise: (client: Object) => client.get(requestUrl),
  });
};
export function saveCollegesIamApplying(colleges: Array<Object>) {
  if (!Array.isArray(colleges) || colleges.length === 0) {
    // $FlowFixMe
    return Promise.reject('No colleges selected');
  }

  return (dispatch: Function) =>
    dispatch({
      types: [
        SAVE_COLLEGES_IAM_APPLYING,
        SAVE_COLLEGES_IAM_APPLYING_SUCCESS,
        SAVE_COLLEGES_IAM_APPLYING_FAIL,
      ],
      promise: (client: Object) =>
        client.post(`${api.colleges}/colleges-im-applying-to`, {
          data: {
            applications: colleges,
          },
        }),
    });
}

export function deleteCollegesIamApplying(applicationIds: number[]) {
  return (dispatch: Function) =>
    dispatch({
      types: [
        DELETE_COLLEGES_IAM_APPLYING,
        DELETE_COLLEGES_IAM_APPLYING_SUCCESS,
        DELETE_COLLEGES_IAM_APPLYING_FAIL,
      ],
      promise: (client: Object) =>
        client.delete(`${api.colleges}/colleges-im-applying-to`, {
          data: {
            applicationIds: applicationIds,
          },
        }),
    });
}

export const deleteCollegesIamApplyingWithNotify = (applicationIds: number[]) => (
  dispatch: Function
) =>
  dispatch(deleteCollegesIamApplying(applicationIds)).then(() => {
    dispatch(
      notify({
        type: 'success',
        title: 'Confirmation',
        content: `${applicationIds.length} college(s) removed successfully`,
      })
    );
  });

export function saveCollegesIamAttending(attending: CollegeID) {
  return (dispatch: Function) => {
    if (!attending) {
      // $FlowFixMe
      return Promise.reject('No colleges selected');
    }

    return dispatch({
      types: [
        SAVE_COLLEGES_IAM_ATTENDING,
        SAVE_COLLEGES_IAM_ATTENDING_SUCCESS,
        SAVE_COLLEGES_IAM_ATTENDING_FAIL,
      ],
      promise: (client: Object) =>
        client.put(`${api.colleges}/college-im-attending`, {
          data: {
            applicationId: Number(attending),
          },
        }),
    }).then((response: Object) => {
      if (response.message) {
        dispatch(
          notify({
            type: 'danger',
            title: 'Update failed',
            content: response.message,
          })
        );
      } else {
        dispatch(
          notify({
            type: 'success',
            title: 'Confirmation',
            content: `The college you're attending has been updated`,
          })
        );
      }
    });
  };
}

export function editApplication(field: string, id: string, value: boolean) {
  return (dispatch: Function) =>
    dispatch({
      type: EDIT_APPLICATION,
      field,
      id,
      value,
    });
}

export function updateCollegesIamApplying(colleges: Array<Object>, hideNotification: boolean) {
  return (dispatch: Function): Function | Promise<any> => {
    if (Array.isArray(colleges) && colleges.length) {
      colleges = colleges.map((college) => {
        if (college.type === '') return { ...college, type: '0' };
        return typeof college.type !== 'number'
          ? { ...college, type: parseInt(college.type, 10) }
          : college;
      });
      return dispatch({
        types: [
          UPDATE_COLLEGES_IAM_APPLYING,
          UPDATE_COLLEGES_IAM_APPLYING_SUCCESS,
          UPDATE_COLLEGES_IAM_APPLYING_FAIL,
        ],
        promise: (client: Object) =>
          client.put(`${api.colleges}/colleges-im-applying-to`, {
            data: {
              applications: colleges,
            },
          }),
      }).then((response: Object) => {
        if (response.message && !hideNotification) {
          dispatch(
            notify({
              type: 'danger',
              title: 'Update failed',
              content: response.message,
            })
          );
        } else if (!hideNotification) {
          dispatch(
            notify({
              type: 'success',
              title: 'Confirmation',
              content: `The colleges you're applying have been updated`,
            })
          );
        }
      });
    }

    return Promise.resolve();
  };
}

export const resetEdits = () => ({
  type: RESET_EDITS,
});

export function changeCollegeApplicationValue(id: string, name: string, value: any) {
  return {
    type: CHANGE_COLLEGE_APP_VALUE,
    id,
    name,
    value,
  };
}

export const changeWereLegacyMatchesLoaded = (wereLegacyMatchesLoaded: boolean) => ({
  type: CHANGE_IS_LOADING_LEGACY_MATCHES,
  wereLegacyMatchesLoaded,
});
