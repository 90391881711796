import { ApolloClient, ObservableQuery, HttpLink, from } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache } from '@apollo/client/cache';

export default function getApolloClient({ session, uri }) {
  const httpLink = new HttpLink({ uri });
  const withAuth = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: session && `Bearer ${session.jwt}`,
      },
    };
  });
  const cache = new InMemoryCache();
  const client = new ApolloClient({
    link: from([withAuth, httpLink]),
    cache,
  });
  return client;
}

// monkey patch to solve issue where once a query fails, no future queries work.
// explaination is here: https://github.com/apollographql/react-apollo/issues/1385#issuecomment-359534837
const originalSetVariables = ObservableQuery.prototype.setVariables;
/* istanbul ignore next */
ObservableQuery.prototype.setVariables = function (variables) {
  if (this.lastError) {
    this.resetLastResults();
  }
  return originalSetVariables.call(this, variables);
};
