import ScriptLoader from 'react-script-loader-hoc';
import { compose } from 'redux';
import { activeMatchUiUrl } from '../../../config/api';
import withGlobals from '../../../components/Globals';

const ACTIVEMATCH_WEBPACK_PUBLIC_URL_NAME = '__webpack_activematch_public_path__';
const origin = activeMatchUiUrl ? new URL(activeMatchUiUrl).origin : '';
global[ACTIVEMATCH_WEBPACK_PUBLIC_URL_NAME] = origin;

export default compose(withGlobals, ScriptLoader(activeMatchUiUrl));
