// @flow

import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { getHighSchool } from 'selectors/highschool';
import { connect } from 'react-redux';
import session from '../../config/session';
import { initAnalytics, addDataLayerValue } from '../../utils/GoogleTagManager';
import { getHash } from '../../utils/sha3';
import { ROLES } from '../../selectors/auth';

const MUTATION_TIMEOUT_MS = 1000;

type Props = {
  location: Object,
  highSchool: Object,
};

type State = {
  title: string,
};

const removeUUID = (string: String) =>
  string.replace(/\/[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}/gi, '');

@withRouter
@connect((state) => ({
  highSchool: getHighSchool(state),
}))
export default class GoogleTagManager extends React.Component<Props, State> {
  static defaultProps = {
    highSchool: {
      name: null,
    },
    location: {
      pathname: '',
    },
  };

  newPathname: boolean = false;

  newTitle: boolean = false;

  observer = null;

  state = {
    title: String(document.title),
    additionalTracking: null,
  };

  gtmDidInitialize: boolean = false;

  pageViewTimeout: any = null;

  componentDidMount() {
    this.observeDocumentTitle();
  }

  observeDocumentTitle() {
    const target = document.querySelector('title');

    let mutationTimeout: any = null;
    this.observer = new MutationObserver((mutations) => {
      const mutation = mutations[mutations.length - 1];
      const addedNodes = mutation && mutation.addedNodes[0] ? mutation.addedNodes[0] : null;
      const removedNodes = mutation && mutation.removedNodes[0] ? mutation.removedNodes[0] : null;
      if (addedNodes) {
        clearTimeout(mutationTimeout);
        const additionalTrackingElement = document.querySelector("meta[name='additionalTracking']");
        let additionalTracking = null;
        if (additionalTrackingElement) {
          try {
            additionalTracking = JSON.parse(additionalTrackingElement.content);
          } catch (e) {
            // No need to handle, just making the parse safe
          }
        }
        this.setState({
          title: String(addedNodes.data),
          additionalTracking,
        });
      }
      if (removedNodes && !addedNodes) {
        mutationTimeout = setTimeout(() => {
          this.setState({
            title: String(null),
            additionalTracking: null,
          });
        }, MUTATION_TIMEOUT_MS);
      }
    });
    if (target) {
      this.observer.observe(target, {
        attributes: false,
        characterData: false,
        childList: true,
        subtree: true,
        attributeOldValue: false,
        characterDataOldValue: false,
      });
    }
  }

  stopObserveDocumentTitle() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  componentWillUnmount() {
    this.stopObserveDocumentTitle();
  }

  componentDidUpdate(prevProps: Object, prevState: Object) {
    const { location, highSchool } = this.props;
    const { title } = this.state;
    if (location.pathname !== prevProps.location.pathname && prevProps.location.pathname !== '/') {
      this.newPathname = true;
    }
    if (title !== prevState.title) {
      this.newTitle = true;
    }
    if (!highSchool.loading) {
      if (location.pathname !== '/' && !this.gtmDidInitialize) {
        clearTimeout(this.pageViewTimeout);
        if (this.newTitle) {
          this.firePV();
        } else {
          this.pageViewTimeout = setTimeout(() => {
            this.firePV();
          }, MUTATION_TIMEOUT_MS);
        }
      }
      if (this.newPathname && this.newTitle) {
        this.fireVPV();
      }
    }
  }

  firePV() {
    const { highSchool } = this.props;
    const { title, additionalTracking } = this.state;

    let shouldFireVP = false;
    const { data } = session;
    const dataLayerValue = {
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
      institutionName: '',
      institution: '',
      userType: data && data.userJobFunction ? data.userJobFunction.toUpperCase() : '',
      grade: data ? data.grade : '',
      userId: '',
      location: document.URL,
      title,
      ...additionalTracking,
    };

    if (data && data.role === ROLES.student) {
      dataLayerValue.userId = getHash(data.id.toString());
    }

    if (data && data.loginUser && data.loginUser.userJobFunction) {
      dataLayerValue.userType = data.loginUser.userJobFunction.toUpperCase();
      dataLayerValue.userId = data.loginUser.userId;
    }

    if (highSchool.hsid || highSchool.nid) {
      if (highSchool.name && highSchool.nid) {
        dataLayerValue.institutionName = highSchool.name;
        dataLayerValue.institution = highSchool.nid;
        shouldFireVP = true;
      }
    } else {
      shouldFireVP = true;
    }

    if (shouldFireVP) {
      this.gtmDidInitialize = true;
      this.newTitle = false;
      addDataLayerValue(dataLayerValue);
      initAnalytics();
    }
  }

  fireVPV() {
    const { highSchool, location } = this.props;
    const { title, additionalTracking } = this.state;

    let shouldFireVPV = false;
    const { data } = session;
    const dataLayerValue = {
      institutionName: '',
      institution: '',
      userType: data && data.userJobFunction ? data.userJobFunction.toUpperCase() : '',
      grade: data ? data.grade : '',
      userId: '',
      event: 'virtualPageView',
      virtualPageURL: removeUUID(location.pathname),
      virtualPage: title,
      location: document.URL,
      title,
      ...additionalTracking,
    };

    if (data && data.role === ROLES.student) {
      dataLayerValue.userId = getHash(data.id.toString());
    }

    if (data && data.loginUser && data.loginUser.userJobFunction) {
      dataLayerValue.userType = data.loginUser.userJobFunction.toUpperCase();
      dataLayerValue.userId = data.loginUser.userId;
    }

    if (highSchool.hsid || highSchool.nid) {
      if (highSchool.name && highSchool.nid) {
        dataLayerValue.institutionName = highSchool.name;
        dataLayerValue.institution = highSchool.nid;
        shouldFireVPV = true;
      }
    } else {
      shouldFireVPV = true;
    }

    if (shouldFireVPV) {
      this.newPathname = false;
      this.newTitle = false;
      addDataLayerValue(dataLayerValue);
    }
  }

  render() {
    return null;
  }
}
